body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #4f4f4f;
  color: #e0e0e0;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #4f4f4f;
}

.App-header {
  flex: 0 0 auto;
  background-color: #b469ff;
  color: white;
  padding: 20px;
  text-align: center;
}

.Page-container {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  background-color: #4f4f4f;
  justify-content: center;
}

.Chat-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.Chat-form {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  max-width: 800px;
}

.Chat-form input {
  background-color: #ffffff;
  color: #000000;
  border-radius: 4px;
  padding: 10px;
  flex: 1;
}

.Chat-form button {
  background-color: #b469ff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.Chat-form button:hover {
  background-color: #b469ff;
}

.message {
  padding: 0px 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  max-width: 70%;
  line-height: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.message.user {
  background-color: #e0e0e0;
  align-self: flex-end;
  text-align: right;
  color: #000;
}

.message.model {
  background-color: #b469ff;
  align-self: flex-start;
  text-align: left;
  color: #ffffff;
}

.message.model p {
  color: #ffffff;
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  appearance: none;
  user-select: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
  margin-left: auto;
  margin-right: auto;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
